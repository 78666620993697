<template>
  <div class="sy-login">
     <div class="phone">
      <img src="../../assets/images/title.png" style="width: 80px;height: 28px;">
       <div class="sy-btn">
         <i  class="el-icon-service sy-i"><span>13681110927</span></i>
         <el-input size="mini" placeholder="输入手机号" v-model="input2"  class="syi-i">
           <el-button slot="append" style="color: #1e1e1e">预约演示</el-button>
         </el-input>
       </div>
    </div>
    <div class="sy-item">
      <div class="sy-left">
          <el-carousel  height="430px" >
            <el-carousel-item v-for="item in lbs" :key="item">
              <img class="lbImg" :src="item">
            </el-carousel-item>
          </el-carousel>
      </div>
    <div class="login-wrapper">
      <h2>开单管理系统</h2>
      <el-form :model="ruleForm" :rules="ruleForm" ref="loginForm" label-width="0">
        <el-form-item label prop="username">
          <el-input v-model="ruleForm.username" prefix-icon="el-icon-user"  placeholder="账号" autofocus="true"
                    @keyup.enter.native="submitForm"></el-input>
        </el-form-item>
        <el-form-item label prop="password">
          <el-input v-model="ruleForm.password" prefix-icon="el-icon-unlock" type="password" placeholder="密码"
                    @keyup.enter.native="submitForm"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom:60px">
        </el-form-item>
        <el-form-item>
          <el-button style="width:100%;background-color: #1FB383;color: #FFFFFF" @click="submitForm('ruleForm')">登 录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
    <div style="margin-top: 38px">
      <img class="imgtt" src="../../assets/images/tt.png" />
    </div>
  </div>
</template>

<script>
  import {setToken,setSwName} from "../../utils/auth";

  export default {
    data: function () {
      return {
        ruleForm: {
          username: '',
          password: '',
        },
        lbs:[
          require("../../assets/images/lb1.jpg"),
          require("../../assets/images/lb2.jpg"),
        ],
        rememberName: true,
        rememberPd: false
      }

    },
    // 页面加载调用获取Cookie值
    mounted() {
      this.getCookie();
    },
    methods: {
      async submitForm(ruleForm) {
        // // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
        // if (this.rememberName && this.rememberPd) {// 记住密码
        //   this.setCookie(this.ruleForm.username, this.ruleForm.password, 30); // 保存期限为30天
        // }
        // else if (this.rememberName) { // 记住用户名
        //   this.setCookie(this.ruleForm.username, "", 30); // 保存期限为30天
        // }
        // else if (this.rememberPd) { // 记住密码
        //   this.setCookie(this.ruleForm.username, this.ruleForm.password, 30); // 保存期限为30天
        // }
        // else {
        //   this.clearCookie(); // 清空 Cookie
        // }
        if (this.ruleForm.username == '' || this.ruleForm.username == null) {
          this.$message({
            showClose: true,
            message: "请输入账号",
            type: 'error'
          });
          return;
        }
        if (this.ruleForm.password == '' || this.ruleForm.password == null) {
          this.$message({
            showClose: true,
            message: "请输入密码",
            type: 'error'
          });
          return;
        }
        let res = await this.$post("/sw/loginWeb", {
          loginName: this.ruleForm.username,
          password: this.ruleForm.password
        })
        if (res.code == 200) {
          setToken(res.token);
          setSwName(res.deptName);
          this.$router.push("/cashiers/cashier");
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      // 设置Cookie
      setCookie(username, password, exdays) { // 用户名, 密码, 保存天数
        let exdate = new Date(); // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
        // 字符串拼接cookie
        window.document.cookie = 'userName=' + username + ';path=/;expires=' + exdate.toGMTString();
        window.document.cookie = 'userPwd=' + password + ';path=/;expires=' + exdate.toGMTString();
      },

      // 读取Cookie
      getCookie() {
        if (document.cookie.length > 0) {
          let arr = document.cookie.split('; '); // 这里显示的格式需要切割一下自己可输出看下
          for (let i = 0; i < arr.length; i++) {
            let arr2 = arr[i].split('='); // 再次切割
            // 判断查找相对应的值
            if (arr2[0] == 'userName') {
              this.ruleForm.username = arr2[1]; // 保存到保存数据的地方
            } else if (arr2[0] == 'userPwd') {
              this.ruleForm.password = arr2[1];
            }
          }
        }
      },

      // 清除Cookie
      clearCookie() {
        this.setCookie('', '', -1); // 修改2值都为空，天数为负1天就好了
      }
    }


  }
</script>

<style lang="scss">
  .sy-login{
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image:url("../../assets/images/bgt.jpg");
    background-size: cover;
  }
  .phone{
    position: absolute;
    top: 50px;
    left: 18%;
    display: flex;
    flex-direction: row;
  }
  .sy-btn{
    display: flex;
    flex-direction: row;
  }
  .sy-i{
    color: #DDDDDD;
    margin-left: 28px;
    font-size: 16px;
    float: left;
    line-height: 30px;
  }
  .sy-i span{
    font-size: 13px;
    line-height: 32px;
    margin-left: 6px
  }
  .syi-i{
    margin-left: 16px;
  }

  .sy-item{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin-top: -98px;
  }

  .sy-left{
    width: 288px;
    height: 430px;
  }
  .lbImg{
    width: 288px;
    height: 430px;
  }
  .imgtt{
    width: 288px;
    height: 32px;
  }

  .login-wrapper {
    width: 100%;
    padding: 40px 30px 0;
    width: 400px;
    height: 430px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba(0, 82, 204, 0.3);
    h2 {
      font-size: 16px;
      padding-bottom: 30px;
    }
  }
</style>
